<template>
  <b-card>
    <b-row>
      <b-col
        v-for="item in dataList"
        :key="item.id"
        cols="12"
        md="4"
        class="mt-1"
      >
        <div
          class="border rounded p-2 mb-2 catalog-button"
          @click="openCatalog(item)"
        >
          <b-badge
            v-if="!item.opened"
            variant="success"
            class="position-absolute zindex-4 position-top-0 m-n1"
          >
            {{ $t('Yeni') }}
          </b-badge>
          <div class="d-flex justify-content-start align-items-center">
            <b-avatar
              :src="item.image? item.image : null"
              size="90"
            />
            <div class="ml-1">
              <div class="font-weight-bolder font-medium-3">
                {{ item.title }}
              </div>
              <div class="font-small-2 text-muted">
                {{ moment(item.expiry_date).format('LL') }} {{ $t('tarihine kadar geçerli') }}
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BAvatar, BBadge,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  name: 'Index',
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
  },
  computed: {
    moment() {
      return moment
    },
    dataList() {
      return this.$store.getters['catalogs/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('catalogs/getDataList')
    },
    openCatalog(item) {
      if (!item.opened) {
        this.$store.dispatch('catalogs/openCatalog', {
          id: item.id,
        })
          .then(response => {
            if (response) {
              this.$router.push(`/app/catalogs/view/${item.id}`)
            }
          })
      } else {
        this.$router.push(`/app/catalogs/view/${item.id}`)
      }
    },
  },
}
</script>
<style>
.catalog-button{
    cursor: pointer;
    display: block;
}
.catalog-button:hover{
    background: var(--light);
}
</style>
